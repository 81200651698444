<template>
    <div class="footer">
        <div class="footer-con">
            <div class="footer-con-a">
                <div class="footer-aboutUs">
                    <h4>关于我们</h4>
                    <span>我们是成熟的企业互联网解决方案服务商。致力于帮助每个企业实现互联网智能经营公司现设有客服事业部、运营事业部、网络事业部、行政事业部四大职能部门...</span>
                </div>
                <div class="footer-menu">
                    <h4>快捷导航</h4>
                    <div class="footer-menu-item">
                        <div class="footer-item-li">
                            <router-link to="/">网站建设</router-link>
                            <router-link to="/">域名注册</router-link>
                            <router-link to="/">小程序开发</router-link>
                            <router-link to="/">虚拟主机</router-link>
                        </div>
                        <div class="footer-item-li">
                            <router-link to="/">分销商城</router-link>
                            <router-link to="/">企业邮箱</router-link>
                            <router-link to="/">微信开发</router-link>
                            <router-link to="/">400电话办理</router-link>
                        </div>
                    </div>
                </div>
                <div class="code"><img src="../static/img/code.png"></div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-bottom-con">
                <span class="footer-left">Copyright © 2011-2020 二进一网络技术有限公司 All Rights Reserved. </span>
<!--                <span class="footer-right">粤ICP备 11058496号  粤公网安备 44030702003038号</span>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="stylus">
.footer
    width 100%
    background #132539
    color #777d85
    font-size 12px
    text-align left

    .footer-con
        display table
        margin 0 auto
        width 1400px
        position relative
        padding 45px 0

    .footer-con-a
       display table/*9*/
       display flex

       flex-direction row

       .footer-aboutUs
           width 300px
           padding-right 150px
           border-right 1px solid #1f3043
           float left/*9*/

           h4
             color #f8f8f8
             font-size 14px

       .footer-menu
           margin-left 50px
           padding-left 100px/*9*/
           display table/*9*/

           h4
             color #f8f8f8
             font-size 14px

           .footer-menu-item
               display flex
               flex-direction row

           .footer-item-li
               display table/*9*/
               display flex
               flex-direction column
               margin 0 50px 0 0px
               float left/*9*/


               a
                   color #777d85
                   text-decoration none
                   line-height 20px
                   display table/*9*/


       .code
            float right
            display block
            position absolute
            right 0
            top 40px/*9*/

            img
                width 150px
                height 150px


    .footer-bottom
        border-top 1px solid #1f3043
        display flex
        justify-content center
        padding 10px 0

        .footer-bottom-con
            width 1400px
            display flex
            flex-direction row
            justify-content space-between
            display table/*9*/
            margin 0 auto/*9*/
            text-align center

            /*.footer-left*/
            /*    float left*/

            /*.footer-right*/
            /*    float right*/
</style>