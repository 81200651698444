<template>
    <div class="product-top">
        <div class="product-top-txt">
            <span class="h1-txt">客户案例</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product'}">客户案例</el-breadcrumb-item>
                <el-breadcrumb-item v-if="detailShow">案例详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            detailShow: {
                type : String,
                default: false
            }
        },
        name: "ProductTop",
        data(){
            return{

            }
        }
    }
</script>

<style scoped lang="stylus">
    .product-top
        width 100%
        background #409eff
        height 120px
        margin-top 80px

        .product-top-txt
            width 1400px
            margin 0 auto
            display block

            .h1-txt
                font-size 30px
                font-weight normal
                color #fff
                text-align left
                display block
                padding-top 25px
                padding-bottom 16px


            >>>.el-breadcrumb__inner
                color #fff
                opacity 0.5
            font-size 12px

            >>>.el-breadcrumb__separator
                color #fff
                opacity 0.5
</style>