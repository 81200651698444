
import Header from '@/components/Header'
import Title from '@/components/Title'
import BottomImage from '@/components/BottomImage'
import Footer from '@/components/Footer'
import ProductTop from '@/components/ProductTop'

const plugins = {
    //注册
    install(Vue) {
        Vue.component('Header',Header); //第一个参数:组件名称,第二个参数:要注册的组件
        Vue.component('Title',Title);
        Vue.component('BottomImage',BottomImage);
        Vue.component('Footer',Footer);
        Vue.component('ProductTop',ProductTop);
    }
}
export default plugins;