<template>
    <div class="bottom-image">
        <img src="../static/img/bottomImg.png">
    </div>
</template>

<script>
    export default {
        name: "BottomImage"
    }
</script>

<style scoped lang="stylus">
    .bottom-image
        width 100%
        height 200px

        img
            width 100%
            height 100%
</style>