<template>
    <div :class="['header',{'home-header': isHome}]" >

        <div class="header-box">
            <img alt="Vue logo" src="../assets/logo-white.png" v-if="isHome" class="logoImg">
            <img alt="Vue logo" src="../assets/logo.png" v-else class="logoImg">


            <el-menu
                    :default-active="this.$route.path"
                    class="el-menu-demo"
                    mode="horizontal"
                    router
                    @select="handleSelect"
            >
                <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
                    <template slot="title">
                      <span> {{ item.navItem }}</span>
                    </template>
                </el-menu-item>
            </el-menu>

<!--            <div v-if="isHome" class="header-contact">欢迎致电：136-2240-1110</div>-->
            <div class="right-tag">
                我们的服务遍布中国
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "header",
        props:{
            isHome:{
                type : Boolean,
                default: false
            }

        },
        data(){
            return{
                activeIndex: '/',
                navList: [
                    { name: "/", navItem: "首页" },
                    { name: "/website", navItem: "网站建设" },
                    { name: "/marketing", navItem: "营销推广" },
                    // { name: "/Product", navItem: "客户案例" },
                    { name: "/about", navItem: "关于我们" },
                ]
            }
        },
        methods: {
            handleSelect(key, keyPath) {
               // console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped lang="stylus">
 .header
    width 100%
    position fixed
    top 0
    z-index 9
    background #fff
    box-shadow 0 0 8px rgba(0,0,0,0.2)

    >>>.el-menu
        background-color transparent

    .header-box
       width 1400px
       margin 0 auto
       flex-direction row
       height 80px
       align-items center
       position relative
       display flex
       display block/*9*/

       .logoImg
           float left/*9*/
           margin-top 10px/*9*/

       .el-menu
           margin-left 300px

       .el-menu-item
           font-size 18px
           font-weight bold
           margin-left 12px

       .el-menu.el-menu--horizontal
          border-bottom none

       .el-menu--horizontal>.el-menu-item.is-active
          border-bottom 3px solid  #409eff

       .el-menu--horizontal>.el-menu-item
          height 80px
          line-height (@height)

       .right-tag
           position absolute
           right 0
           padding 8px 15px
           color #fff
           font-size 12px
           background #409eff
           top 0

       .header-contact
           font-size 12px
           color #999
           position absolute
           right 0
           background-image linear-gradient(to right, #409eff , #217bd7)
           padding 5px 10px
           border-radius 5px
           box-shadow 0 0 5px rgba(0,0,0,0.12)

 .home-header
     background rgba(255,255,255,0.05)
     box-shadow 0 0 8px rgba(0,0,0,0)

     >>>.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-submenu .el-submenu__title:hover
             background-color transparent
             opacity 0.8

     >>>.el-menu--horizontal > .el-menu-item
       color #fff

     >>>.el-menu--horizontal>.el-menu-item.is-active
        border-bottom none !important
        color #81b1ea !important

     .header-contact
         color #eee !important
</style>