<template>
    <div class="title-con">
        <span class="title">{{title}}</span>
        <div class="sub-title">
            <div class="line"></div>
            <span class="sub-title-txt">{{subTitle}}</span>
            <div class="line"></div>
        </div>
        <span class="min-title">{{minTitle}}</span>
    </div>
</template>

<script>
    export default {
        name: "Title",
        props:{
            title:{
                type: String,
                default: '标题'
            },
            subTitle:{
                type: String,
                default: '副标题'
            },
            minTitle:{
                type: String,
                default: '标题三'
            }
        },
        data(){
            return{

            }
        }
    }
</script>

<style scoped lang="stylus">
.title-con
    margin 0 auto
    display block
    display table/*9*/

.title
    font-size 40px
    color #409eff
    margin-top 40px
    display block
    text-transform uppercase

.sub-title

    flex-direction row
    align-items center
    justify-content center
    margin 5px auto
    display flex
    display table/*9*/


    .sub-title-txt
        color #606266
        font-size 24px
        margin 0 40px
        float left/*9*/



    .line
        width 100px
        height 1px
        background #ddd
        float left/*9*/
        margin-top 15px/*9*/




.min-title
    font-size 12px
    color #606266
    line-height 30px
    width 100%/*9*/
    display table/*9*/

</style>