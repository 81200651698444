export  default
[
     {image:require('../static/case/c-01.jpg'),name:'特变电工',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-01.png'),
          detailCon:{backgroundOne:require('../static/caseDetail/1-backgroundOne.png'),oneTitle:"绿色和平中文官网",
               subtitleOneOne:'新鸿儒从世界绿色和平组织的宗旨出发，以轻松、活泼、充满人文性以及自然色彩的官方网,站助力绿色和平组织的公益活动宣传。',

               oneImg: require('../static/caseDetail/1-img0ne.png'),
               twoTitle:'世界绿色和平 色彩范围',
               twoSubTitle:'在logo主色基础上进行延伸，提升整体视觉舒适度',
               twoBlockColor:[{colorTxt:'#DF8224'},{colorTxt:'#67cd00'},{colorTxt:'#666666'}],
               twoImg: require('../static/caseDetail/1-block-two-img.png'),
               blockBackground:'#fffae2',
               threeImg:require('../static/caseDetail/1-backgroundthree.png'),
               threeTxt:'活动运营',
               threeSubTxt:'绿色和平网站举办《别让清风卷走老虎》等多个线上公益活动，吸引用户参与。\n' +
                   '新鸿儒长期负责其网站的维护和运营推广工作，保障网站稳定、有效运行。',
               fourTxt:'主题活动',
               fourSubTxt:'世界绿色和平官网',
               fourImg:require('../static/caseDetail/1-backgroundFour.png')
           }
     },
     {image:require('../static/case/c-02.jpg'),name:'雷诺',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-02.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/2-onebg.png'), oneTitle: "雷诺汽车官网",
               subtitleOneOne: '雷诺汽车品牌官网与互动平台的有机结合，用品牌官网传递雷诺品牌价值。',

               oneImg: require('../static/caseDetail/2-oneImg.png'),
               twoTitle: '雷诺 色彩范围',
               twoSubTitle: '根据雷诺vi主色，制定雷诺集团WEBCI',
               twoBlockColor: [{colorTxt: '#F8B600'}, {colorTxt: '#333333'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/2-twoImg.png'),
               blockBackground: '#edebeb',
               threeImg: require('../static/caseDetail/2-threeImg.png'),
               threeTxt: '车友互动',
               threeSubTxt: '雷诺打造诺生活车友互动平台——可供车主体验雷诺产品、分享驾乘感受、参与车主活动、建言品牌建设的多元化互动交流平台。',
               fourTxt: '一展雷诺风采',
               fourSubTxt: '全新官网',
               fourImg: require('../static/caseDetail/2-fourImg.png')
          }
     },
     {image:require('../static/case/c-03.jpg'),name:'玲珑轮胎',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-03.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/3-onebg.png'), oneTitle: "以产品为主导的品牌官网",
               subtitleOneOne: '玲珑轮胎以产品为主导，通过多种交互方式，展现产品的创新优势和应用领域。',

               oneImg: require('../static/caseDetail/3-oneImg.png'),
               twoTitle: '玲珑轮胎 色彩范围',
               twoSubTitle: '与VI主色保持一致，塑造统一的品牌识别体系',
               twoBlockColor: [{colorTxt: '#014099'}, {colorTxt: '#6EB92B'}, {colorTxt: '#0A72E1'}],
               twoImg: require('../static/caseDetail/3-twoImg.png'),
               blockBackground: '#fff',
               threeImg: require('../static/caseDetail/3-threeImg.png'),
               threeTxt: '智能产品搜索',
               threeSubTxt: '玲珑轮胎官网第一屏设置智能搜索通道，通过多维度分类进行精准查询，便于用户直接找到自己想要的产品。',
               fourTxt: '多维度聚合',
               fourSubTxt: '玲珑轮胎品牌介绍',
               fourImg: require('../static/caseDetail/3-fourImg.png')
          }

     },
     {image:require('../static/case/c-04.jpg'),name:'居然之家',subTitle:'o2o平台建设 APP开发',
          imageHome:require('../static/img/home-case-04.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/4-onebg.png'), oneTitle: "O2O家居设计电商服务平台",
               subtitleOneOne: '居然在线是一个面向设计师和消费者的线上线下无缝链接的（O2O）一体化电商服务平台，消费者不仅能在\n' +
                   '线购买家具、建材等家居商品，还能在线进行设计、装修和DIY。',

               oneImg: require('../static/caseDetail/4-oneImg.png'),
               twoTitle: '居然之家 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#0168B7'}, {colorTxt: '#333333'}, {colorTxt: '#4C4C4C'}],
               twoImg: require('../static/caseDetail/4-twoImg.png'),
               blockBackground: '#e9f5ff',
               threeImg: require('../static/caseDetail/4-threeImg.png'),
               threeTxt: '搭建家居服务生态圈',
               threeSubTxt: '居然在线搭建起全新的家居服务生态圈，让设计简单化、装修透明化、施工标准化，真正解决了消费者家居设计过程中的痛点。',
               fourTxt: '设计师专有平台',
               fourSubTxt: '居然在线分为消费者和设计师两个入口，各有侧重',
               fourImg: require('../static/caseDetail/4-fourImg.png')
          }
     },
     {image:require('../static/case/c-05.jpg'),name:'新疆金风科技',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-05.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/5-onebg.png'), oneTitle: "金风科技官网",
               subtitleOneOne: '功塑造金风科技创新、科技、国际化的品牌形象。',

               oneImg: require('../static/caseDetail/5-oneImg.png'),
               twoTitle: '居然之家 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#0168B7'}, {colorTxt: '#333333'}, {colorTxt: '#4C4C4C'}],
               twoImg: require('../static/caseDetail/5-twoImg.png'),
               blockBackground: '#e9f5ff',
               threeImg: require('../static/caseDetail/5-threeImg.png'),
               threeTxt: '搭建家居服务生态圈',
               threeSubTxt: '居然在线搭建起全新的家居服务生态圈，让设计简单化、装修透明化、施工标准化，真正解决了消费者家居设计过程中的痛点。',
               fourTxt: '设计师专有平台',
               fourSubTxt: '居然在线分为消费者和设计师两个入口，各有侧重',
               fourImg: require('../static/caseDetail/5-fourImg.png')
          }
     },
     {image:require('../static/case/c-06.jpg'),name:'中国忠旺',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-06.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/6-onebg.jpg'), oneTitle: "忠旺控股官网",
               subtitleOneOne: '网站体现品牌行业应用领域，突出企业实力形象。充分利用主视觉区，传递企业的品牌印象，丰富用户视觉体验。',

               oneImg: require('../static/caseDetail/6-oneImg.png'),
               twoTitle: '忠旺控股 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#BF8C4D'}, {colorTxt: '#FF0902'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/6-twoImg.png'),
               blockBackground: '#FFF',
               threeImg: require('../static/caseDetail/6-threeImg.png'),
               threeTxt: '安全解决方案',
               threeSubTxt: '技术开发上，新鸿儒为中国忠旺提供了完善的、安全的技术方案，解决了忠旺一直面临的网站攻击问题，保障官网稳定、高效运行。\n',
               fourTxt: '主视觉设计',
               fourSubTxt: '忠旺控股',
               fourImg: require('../static/caseDetail/6-fourImg.png')
          }
     },
     {image:require('../static/case/c-07.jpg'),name:'大公国际',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-07.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/7-onebg.jpg'), oneTitle: "大公国际官网",
               subtitleOneOne: '网站整体简约庄重，凸显行业特点，为用户塑造良好的品牌信任感和品质感',

               oneImg: require('../static/caseDetail/7-oneImg.png'),
               twoTitle: '大公国际 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#DB0000'}, {colorTxt: '#F3F3F3'}, {colorTxt: '#F1F1F1'}],
               twoImg: require('../static/caseDetail/7-twoImg.png'),
               blockBackground: '#FFF',
               threeImg: require('../static/caseDetail/7-threeImg.png'),
               threeTxt: '专业化、精细化',
               threeSubTxt: '新鸿儒与大公国际“专业化、精细化”的理念相一致，在频道页设计时注重细节和品质，由外至内，为大公国际提升整体品牌形象。',
               fourTxt: '诠释大公产品体系',
               fourSubTxt: '大公资信逻辑清晰的结构图',
               fourImg: require('../static/caseDetail/7-fourImg.png')
          }
     },
     {image:require('../static/case/c-08.jpg'),name:'对外协会官网',subTitle:'网络建设',
          imageHome:require('../static/img/home-case-08.png'),
          detailCon: {
               backgroundOne: require('../static/caseDetail/8-onebg.jpg'), oneTitle: "铸造中外人民交流的互联网通道",
               subtitleOneOne: '中国人民对外友好协会携手新鸿儒，通过打造官网，建立中外人民的交流窗口、互动专区和在线服务平台',

               oneImg: require('../static/caseDetail/8-oneImg.png'),
               twoTitle: '协会官网 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#0168B7'}, {colorTxt: '#333333'}, {colorTxt: '#4C4C4C'}],
               twoImg: require('../static/caseDetail/8-twoImg.png'),
               blockBackground: '#FFF',
               threeImg: require('../static/caseDetail/8-threeImg.png'),
               threeTxt: '政务门户设计',
               threeSubTxt: '新鸿儒在网站整体设计上以协会理念为核心，注重人文性和公益性的体现，统一色系和风格，最终呈现出清新简洁的友好性网络平台，为用户提供良好的浏览体验。',
               fourTxt: '专题活动',
               fourSubTxt: '中国人民对外友好协会',
               fourImg: require('../static/caseDetail/8-fourImg.png')
          }
     },
     {image:require('../static/case/c-09.jpg'),name:'科大讯飞',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/9-onebg.png'), oneTitle: "科大讯飞语音交互官网",
               subtitleOneOne: '科大讯飞整体页面简洁、信息高效，互动性效果强！',

               oneImg: require('../static/caseDetail/9-oneImg.png'),
               twoTitle: '科大讯飞色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#175191'}, {colorTxt: '#FFB300'}, {colorTxt: '#888888'}],
               twoImg: require('../static/caseDetail/9-twoImg.png'),
               blockBackground: '#FFF',
               threeImg: require('../static/caseDetail/9-threeImg.png'),
               threeTxt: '搭建家居服务生态圈',
               threeSubTxt: '网站以蓝色为主要色调，以“语音引擎-教育产品\n' +
                   '-手机应用-互动音乐-行业应用-关于讯飞”为主要栏目导航，\n' +
                   '既宣传了产品，又树立了企业品牌形象，同时取得了和用户进行相关语音产品知识普及的互动效果，帮助语音技术在互联网 开辟了新的传播阵地。',
               fourTxt: '语音点亮世界',
               fourSubTxt: '语音交互',
               fourImg: require('../static/caseDetail/9-fourImg.png')
          }
     },
     {image:require('../static/case/c-10.jpg'),name:'北辰时代',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/10-onebg.jpg'), oneTitle: "北辰集团官网建设",
               subtitleOneOne: '开启北辰集团互联网+的官方入口，助力北辰集团智能制造和互联网+的完美融合\n',

               oneImg: require('../static/caseDetail/10-oneImg.png'),
               twoTitle: '北辰集团 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#005183'}, {colorTxt: '#000000'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/10-twoImg.png'),
               blockBackground: '#FFF',
               threeImg: require('../static/caseDetail/10-threeImg.jpg'),
               threeTxt: '通过细节设计\n' +
                   '彰显品牌实力',
               threeSubTxt: '网站首页以最新的交互技术和动态的banner效果吸引用户关注，展现北辰企业实力和品牌形象，同时新鸿儒注重细节设计，各栏目页在风格统一的基础上各有特色。',
               fourTxt: '塑造北辰风范',
               fourSubTxt: '恢弘视觉场景',
               fourImg: require('../static/caseDetail/10-fourImg.png')
          }
     },
     {image:require('../static/case/c-11.jpg'),name:'中意财险',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/11-onebg.jpg'), oneTitle: "中意财险官网",
               subtitleOneOne: '中意财险与新鸿儒展开快捷合作，以新官网开启互联网之路，多渠道宣传企业产品，传播品牌形象。',

               oneImg: require('../static/caseDetail/11-oneImg.png'),
               twoTitle: '中意财险 色彩范围',
               twoSubTitle: '主色与企业VI保持一致',
               twoBlockColor: [{colorTxt: '#AE0E16'}, {colorTxt: '#F6F4F5'}, {colorTxt: '#F1F1F1'}],
               twoImg: require('../static/caseDetail/11-twoImg.png'),
               blockBackground: '#fff4f1',
               threeImg: require('../static/caseDetail/11-threeImg.png'),
               threeTxt: '客户服务',
               threeSubTxt: '网站设置客户服务专区，支持客户下载文件、查找网点、在线提问，同时根据用户需求，详细介绍保险产品，引导客户进行咨询和购买。\n',
               fourTxt: '塑造品牌信任感',
               fourSubTxt: '中意财险官网',
               fourImg: require('../static/caseDetail/11-fourImg.png')
          }
     },
     {image:require('../static/case/c-12.jpg'),name:'当代商城',subTitle:'o2o平台建设 APP开发',
          detailCon: {
               backgroundOne: require('../static/caseDetail/12-onebg.jpg'), oneTitle: "当代商城官网",
               subtitleOneOne: '网站整体以时尚、简约和前卫为主体风格，和当代商城“品牌立店，前卫经营”\n' +
                   '的方针相呼应，形成线上线下统一的品牌形象。',

               oneImg: require('../static/caseDetail/12-oneImg.png'),
               twoTitle: '当代商城官网 色彩范围',
               twoSubTitle: '大胆采用冷色系，提升网站高端气质',
               twoBlockColor: [{colorTxt: '#E23922'}, {colorTxt: '#000000'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/12-twoImg.png'),
               blockBackground: '#ffebfd',
               threeImg: require('../static/caseDetail/12-threeImg.png'),
               threeTxt: '交互彰显个性',
               threeSubTxt: '网站主视觉采用交互技术，\n' +
                   '有效传达当代商城整体品牌形象。\n' +
                   '整站运用大量动态展示效果，增强网站的互动体验，\n' +
                   '彰显个性。同时，采用分布式加载技术，\n' +
                   '缓解服务器压力提升加载速度。',
               fourTxt: '时尚设计\n' +
                   '炫酷交互',
               fourSubTxt: '当代商城',
               fourImg: require('../static/caseDetail/12-fourImg.png')
          }
     },
     {image:require('../static/case/c-13.jpg'),name:'东方园林',subTitle:'官网设计',
          detailCon: {
               backgroundOne: require('../static/caseDetail/13-onebg.jpg'), oneTitle: "东方园林官网",
               subtitleOneOne: '时尚带感的动态效果、互动浏览体验，不规则的图片和导航布局，\n' +
                   '都彰显出了东方园林的别具一格和企业特质。',

               oneImg: require('../static/caseDetail/13-oneImg.png'),
               twoTitle: '东方园林 色彩范围',
               twoSubTitle: '三种颜色形成视觉互补，构成东方园林的色彩体系',
               twoBlockColor: [{colorTxt: '#574425'}, {colorTxt: '#000000'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/13-twoImg.png'),
               blockBackground: '#ffF',
               threeImg: require('../static/caseDetail/13-threeImg.png'),
               threeTxt: '极具设计感',
               threeSubTxt: '网站引导页通过FLASH技术打造灵动、飘逸的\n' +
                   '交互效果，独具匠心的设计给人留下深刻印象；页面\n' +
                   '主视觉采用不规则的框架结构，形成独具\n' +
                   '艺术感、时尚感、设计感的冲击力。',
               fourTxt: '企业意境',
               fourSubTxt: '主视觉banner成功塑造',
               fourImg: require('../static/caseDetail/13-fourImg.png')
          }
     },
     {image:require('../static/case/c-14.jpg'),name:'东方漆',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/14-onebg.jpg'), oneTitle: "都芳漆互联网焕新计划",
               subtitleOneOne: '都芳漆联手新鸿儒，打造互联网全新视觉形象，以更环保、低碳、健康的品牌角色亮相中国！',

               oneImg: require('../static/caseDetail/14-oneImg.png'),
               twoTitle: '都芳漆 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内，既能保证视觉色彩的丰富性，又能保证页面的简洁性',
               twoBlockColor: [{colorTxt: '#990100'}, {colorTxt: '#E76B0B'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/14-twoImg.png'),
               blockBackground: '#ffF',
               threeImg: require('../static/caseDetail/14-threeImg.png'),
               threeTxt: '都芳漆的中国美',
               threeSubTxt: '中文官网秉承都芳漆的品质和理念，力求做到简洁、明快。\n' +
                   '整体设计风格与集团网站相呼应，同时尊重中国大众审\n' +
                   '美习惯，表现出中国区官网的独特个性和魅力。',
               fourTxt: '展示都芳漆的每一面',
               fourSubTxt: '历史与新闻交错',
               fourImg: require('../static/caseDetail/14-fourImg.png')
          }
     },
     {image:require('../static/case/c-15.jpg'),name:'水务官网建设',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/15-onebg.png'), oneTitle: "打造国中控股互联网品牌体系",
               subtitleOneOne: '新鸿儒持续为国中控股、国中水务打造官方网站，在兼顾同一体系内企业的视觉统一性的基础上，通过不\n' +
                   '同的表达方式塑造个性化的品牌官网',

               oneImg: require('../static/caseDetail/15-oneImg.png'),
               twoTitle: '国中控股 色彩范围',
               twoSubTitle: '主色与辅助色定位在三种以内',
               twoBlockColor: [{colorTxt: '#5E82B2'}, {colorTxt: '#333333'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/15-twoImg.png'),
               blockBackground: '#ffF',
               threeImg: require('../static/caseDetail/15-threeImg.png'),
               threeTxt: '集展示、互动、营销于一体',
               threeSubTxt: '网站主视觉区域体现集团的行业领域。\n' +
                   '内容区重点规划旗下产业、股票信息、资讯，突出集团品牌实力， 同时注重网站内容的时效性。',
               fourTxt: '回归为信息\n' +
                   '服务的本质',
               fourSubTxt: '丰富信息表达方式',
               fourImg: require('../static/caseDetail/15-fourImg.png')
          }
      },
     {image:require('../static/case/c-16.jpg'),name:'长城宽带',subTitle:'网络建设',
          detailCon: {
               backgroundOne: require('../static/caseDetail/16-onebg.jpg'), oneTitle: "长城宽带官网",
               subtitleOneOne: '新鸿儒以用户体验为核心，为长城宽带规划，设计，开发品牌官网，助力长城宽\n' +
                   '带实现业务拓展和线上服务。',

               oneImg: require('../static/caseDetail/16-img0ne.png'),
               twoTitle: '长城宽带 色彩范围',
               twoSubTitle: '网站配色方案与logo保持一致，形成统一的视觉识别系统',
               twoBlockColor: [{colorTxt: '#FF7C0A'}, {colorTxt: '#723499'}, {colorTxt: '#666666'}],
               twoImg: require('../static/caseDetail/16-twoImg.png'),
               blockBackground: '#fff8ef',
               threeImg: require('../static/caseDetail/16-threeImg.png'),
               threeTxt: '友好的用户\n' +
                   '服务体验',
               threeSubTxt: '新鸿儒为长城宽带规划企业级用户服务专区、网点查询\n' +
                   '专区，方便用户及时享受线上服务。同时，长城宽带官网实现\n' +
                   '在线修改密码、查询费用、缴费充值、查询积分\n' +
                   '等功能，极大的提升了用户体验。\n',
               fourTxt: '回归为信息\n' +
                   '服务的本质',
               fourSubTxt: '“精诚服务，全网关怀”',
               fourImg: require('../static/caseDetail/16-fourImg.png')
          }
     }
 ]
