<template>
  <div class="home">
   <Header :isHome="isHome"></Header>

   <!-- 走马灯-->
   <div class="block">
    <el-carousel height="580px" width="100%">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <el-image :src="item.imgSrc" fit="fill"></el-image>
          </el-carousel-item>
      </el-carousel>
  </div>

  <div class="home-service">
      <Title title="SERVICE AREA"
             subTitle="我们的服务领域"
             minTitle="长期服务上千家品牌客户经验，让我们成长为互联网精英公司——集策划思维、设计执行、服务意识于一体的建站公司。"
      ></Title>

      <div class="home-service">
             <div class="service-image">
                 <img src="../static/img/home-part-01.png" width="700" height="300">
                 <img src="../static/img/home-part-02.png" width="700" height="300">
             </div>
          <div class="service-con">
              <div class="service-txt">
                  <img src="../static/img/home-part-a.png" />
                  <div class="service-info">
                      <h1>移动应用建设方案</h1>
                      <span>响应式网站建设，实现全网营销，<br>
                        一站适应多终端，一样的建站，<br>
                        不一样的体验。</span>
                  </div>
              </div>
              <div class="service-txt">
                  <img src="../static/img/home-part-b.png" />
                  <div class="service-info">
                      <h1>电商平台建设方案</h1>
                      <span>移动应用开发服务，<br>
                            为企业深度挖掘移动流量，<br>
                            转流量为消费提供了有力支持。<br>
                      </span>
                  </div>
              </div>
              <div class="service-txt">
                  <img src="../static/img/home-part-c.png" />
                  <div class="service-info">
                      <h1>企业云服务</h1>
                      <span>金牌服务商专业落地服务，<br>
                            服务响应比厂商更及时。
                      </span>
                  </div>
              </div>
              <div class="service-txt">
                  <img src="../static/img/home-part-d.png" />
                  <div class="service-info">
                      <h1>SEO全网推广</h1>
                      <span>抢占各大搜索引擎首页、<br>
                        提升浏览量，访客量、订单量。</span>
                  </div>
              </div>
          </div>
      </div>
  </div>

<!-- <div class="home-case">-->
<!--     <Title title="excellent case"-->
<!--            subTitle="精品案例"-->
<!--            minTitle="用技术与专注，将作品通过艺术和技术的结合，打造精品案例。"-->
<!--     ></Title>-->
<!--     <div class="home-case-con">-->
<!--         <div class="case-list">-->
<!--             <router-link v-for="(item, index) in urls.slice(0, 8)" :to="{path:'/productDetail', query:{name:item.name}}" class="case-image">-->
<!--                 <el-image  :src="item.imageHome"></el-image>-->
<!--                 <div class="case-txt"><h2>{{item.name}}</h2></div>-->
<!--             </router-link>-->
<!--             <el-button type="primary" plain @click="gotoProduct" class="more-case" size="medium">更多案例</el-button>-->
<!--         </div>-->
<!--     </div>-->

<!-- </div>-->
<!--底部图片-->
      <BottomImage></BottomImage>
      <Footer></Footer>
<!--    <img alt="Vue logo" src="../assets/logo.png">-->

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import caseDetail from './caseDetail.js'
export default {
  name: 'Home',
  components: {
     // Header
    // HelloWorld
  },
    data(){
      return{
          bannerList:[
              {imgSrc: require('../static/img/home-banner-01.jpg')},
              {imgSrc: require('../static/img/home-banner-02.jpg')},
              {imgSrc: require('../static/img/home-banner-03.jpg')}
          ],

          urls:caseDetail,
          isHome:true
      }
    },


    mounted() {
        window.addEventListener('scroll', this.windowScroll)
    },


    methods:{
        gotoProduct(){
            this.$router.push('/product')

        },
        windowScroll() {
            // 滚动条距离页面顶部的距离
            // 以下写法原生兼容
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop > 150){
                this.isHome = false
            }else
            {
                this.isHome = true
            }
           //console.log(scrollTop);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.windowScroll)
    }

}
</script>
<style scoped lang="stylus">
.block
    width 100%

   .el-image
      width 100%
      height 100%

.home-service
    width 1400px
    display block
    margin 10px auto
    padding-bottom 20px

.service-image
   height 300px
   overflow hidden

.service-con
    width: 1400px;
    flex-direction row
    box-shadow 0 0 1px  #dbe6f1
    display flex
    display table/*9*/

    img
        float left/*9*/

    .service-txt
        height 106px/*9*/
        flex-direction row
        width 25%
        border 1px solid #dbe6f1
        box-sizing border-box
        background #fcfdff
        display table/*9*/
        float left/*9*/
        display flex

       .service-info
          text-align left
          border-right 1px solid #dbe6f1
          box-sizing border-box
          float left/*9*/


          &:last-child
              border-right none

          h1
           font-size 16px
           color  #606266

          span
             color  #606266
             font-size 12px


.home-case
    width 100%
    background #f0f7ff
    padding-top 10px
    padding-bottom 40px

    .home-case-con
        width 1400px
        display block
        margin 16px auto 0



.case-list
     width 100%
     display flex
     flex-direction row
     flex-wrap wrap
     justify-content center


    .case-image
        width 25%
        position relative
        float left/*9*/

        .el-image
            width 350px
            height 250px

        .case-txt
           display none
           position absolute
           top 0
           left 0
           z-index 2

        &:hover
          .case-txt
               display flex
               width 100%
               height 254px
               align-items center
               justify-content center
               background rgba(8,135,255,0.8)
               color #fff

    .more-case
        margin-top 25px
        padding 12px 50px
</style>